<template>
  <div>
    <div id="modal" v-if="showModal" class="rounded">
      <div class="p-2">
        <div class="row align-items-stratch">
          <div class="col-8 my-3">
            <label for="">Catégorie</label>
            <select class="form-select" v-model="item.section_reference">
              <option
                v-for="(section, index) in sections"
                :key="index++"
                :value="section.reference"
              >
                {{ section.fullName }}
              </option>
            </select>
          </div>

          <div class="col-4 my-3">
            <label for=""> Code </label>
            <input type="text" v-model="item.code" class="form-control" />
          </div>

          <div class="col-12 my-3">
            <label for="">Désignation</label>
            <input type="text" v-model="item.fullName" class="form-control" />
          </div>
        </div>

        <div class="row">
          <div class="col my-3">
            <label for=""> Prix TTC</label>
            <input type="text" v-model="item.priceTTC" class="form-control" />
          </div>

          <div class="col my-3">
            <label for="">Unité </label>
            <select class="form-select" v-model="item.measuring">
              <option value="KG">KG</option>
              <option value="U">Unité</option>
              <option value="L">Litre</option>
              <option value="M">Mètre</option>
            </select>
          </div>

          <div class="col my-3">
            <label for=""> TVA </label>
            <select class="form-select" v-model="item.tva">
              <option value="0">0 %</option>
              <option value="20" :selected="true">20 %</option>
              <option value="15">15 %</option>
              <option value="10">10 %</option>
              <option value="7">7 %</option>
              <option value="5">5 %</option>
              <option value="2.5">2.5 %</option>
            </select>
          </div>

          <div class="col-12 my-3">
            <label for="">Description</label>
            <textarea
              v-model="item.description"
              class="form-control"
            ></textarea>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <button class="btn btn-secondary" @click="showModal = !showModal">
              <i class="bi bi-check-square-fill"></i> Fermer
            </button>
          </div>
          <div class="col-auto">
            <button class="btn btn-success" @click="saveItem(item)">
              <i class="bi bi-check-square-fill"></i> Enregistrer
            </button>
          </div>
          <div class="col-auto">
            <button class="btn btn-primary" @click="saveItem(item)">
              <i class="bi bi-check-square-fill"></i> Enregistrer & Contenue
              l'achat
            </button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row px-2">
      <div :class="errors != [] ? 'rounded bg-white border' : ''">
        <div v-for="(error, index) in errors" :key="index++" class="col-12">
          <div class="text-danger py-1">
            {{ index + " - " + error }}
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row align-items-end">
      <div class="col-3 my-3">
        <label for="">Catégorie</label>
        <select v-model="section_reference" class="form-select">
          <option value="" selected>Choisir une catégorie</option>
          <option
            v-for="(section, index) in sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col-4 my-3">
        <!-- <label for="">Produit</label>
        <select
          v-model="purchase_item.item_reference"
          @change="onItemChangeSelected"
          class="form-select"
        >
          <option
            v-for="(item, index) in filteredItems"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select> -->

        <label for="itemInput">Produit</label>
        <input
          v-model="item_reference"
          @input="onItemChangeSelected(item_reference)"
          list="itemList"
          class="form-control"
          id="itemInput"
        />
        <datalist id="itemList">
          <option
            v-for="(item, index) in filteredItems"
            :key="index"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </datalist>
      </div>

      <div class="col-5 my-3">
        <label for="itemFullName"> Article </label>

        <input
          id="itemFullName"
          class="form-control"
          :value="selectedItem ? selectedItem.fullName : ''"
          disabled
        />
      </div>
      <div class="col-3 my-3">
        <label for="tva"> TVA </label>

        <input
          id="tva"
          class="form-control"
          :value="selectedItem ? selectedItem.tva +' %' : ''"
          disabled
        />
      </div>

      <div class="col-3 my-3">
        <label for="measuring"> Unité </label>
        <input
          id="measuring"
          class="form-control"
          :value="selectedItem ? selectedItem.measuring : ''"
          disabled
        />
      </div>
      <div class="col-2 my-3">
        <label for="">Prix TTC</label>
        <input
          type="text"
          v-model="purchase_item.priceTTC"
          @input="calculatePriceHT"
          class="form-control"
        />
      </div>

      <div class="col-2 my-3">
        <label for="">Prix HT</label>
        <input
          type="text"
          v-model="purchase_item.priceHT"
          @input="calculatePriceTTC"
          class="form-control"
        />
      </div>

      <div class="col-2 my-3">
        <label for="quantity"> Quantité </label>
        <input
          name="quantity"
          type="text"
          v-model="purchase_item.quantity"
          @input="calculateTotalPrice"
          class="form-control"
        />
      </div>

      <div class="col-2 my-3">
        <label for="">Total TTC</label>
        <input
          type="text"
          :value="
            (purchase_item.priceTTC * purchase_item.quantity) | toFixedWithSpace
          "
          class="form-control"
          disabled
        />
      </div>

      <div class="col-2 my-3">
        <label for="">Total HT</label>
        <input
          type="text"
          :value="
            (purchase_item.priceHT * purchase_item.quantity) | toFixedWithSpace
          "
          class="form-control"
          disabled
        />
      </div>

      <div class="col-3 my-3">
        <label for=""> Stock <span class="text-danger">*</span> </label>
        <select
          v-model="purchase_item.stock_reference"
          class="form-select"
          :class="{ 'is-invalid': errors.quantity }"
        >
          <option value="" selected>Créer un nouveau stock</option>
          <option
            v-for="(stock, index) in filteredStocks"
            :key="index"
            :value="stock.reference"
            :selected="true"
          >
            {{ stock.fullName }} Quantité : {{ stock.quantity }}
            {{ stock.measuring }}
          </option>
        </select>
        <div v-if="errors.quantity" class="invalid-feedback">
          {{ errors.quantity[0] }}
        </div>
      </div>

      <div class="col my-3">
        <label for=""> Désignation de stock </label>
        <!-- <input
          type="text"
          v-model="purchase_item.fullName"
          :disabled="purchase_item.stock_reference != ''"
          class="form-control"
        /> -->

        <select
          v-model="purchase_item.fullName"
          :disabled="purchase_item.stock_reference != ''"
          class="form-select"
        >
          <option
            v-for="(stockFullName, index) in stockFullNames"
            :key="index++"
            :value="stockFullName.fullName"
            :selected="true"
          >
            {{ stockFullName.fullName }}
          </option>
        </select>
      </div>
    </div>
    <div class="row justify-content-end align-items-end">
      <div class="col my-3">
        <label for="">Remarque </label>
        <input
          type="text"
          v-model="purchase_item.remark"
          class="form-control"
        />
      </div>

      <div class="col-auto my-3">
        <label for=""></label>
        <button
          type="button"
          class="btn btn-success"
          @click="save(purchase_item)"
        >
          <i class="bi bi-plus-circle"></i> Insérer
        </button>
      </div>

      <div class="col-auto my-3">
        <label for=""> </label>
        <button
          type="button"
          class="btn btn-secondary d-flex align-items-center"
          @click="refresh()"
        >
          <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
          <span> Actualiser </span>
        </button>
      </div>

      <div class="col-auto my-3">
        <label for=""></label>
        <button class="btn btn-primary" type="button" @click="showModal = true">
          <i class="bi bi-plus-circle"></i> Créer article
        </button>
      </div>
    </div>
    <hr />
    <div class="row justify-content-end align-items-end">
      <div class="col-5">
        <label for=""> Importation à partir des commandes fournisseurs </label>
        <select v-model="order_reference" class="form-select">
          <option
            v-for="(order, index) in orders"
            :key="index++"
            :value="order.reference"
          >
            {{ order.date | date }}-
            <span v-if="order.supplier"> {{ order.supplier.society }}- </span>
            {{ order.reference }}
          </option>
        </select>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button
          type="button"
          class="btn btn-warning d-flex align-items-center"
          @click="importData(order_reference)"
        >
          <i class="bi bi-upload fs-4 me-2"></i>
          <span> Importer </span>
        </button>
      </div>
    </div>
    <br />

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Prix TTC</th>
          <th scope="col">Prix HT</th>
          <th scope="col">Quantité</th>
          <th scope="col">Montant Total</th>
          <th scope="col">Stock</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in purchase_items" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>
          <td class="">
            <span v-if="item.priceTTC != null">
              {{ item.priceTTC | toFixedWithSpace }}
            </span>
          </td>

          <td class="">
            <span v-if="item.priceHT != null">
              {{ item.priceHT | toFixedWithSpace }}
            </span>
          </td>
          <td class="">
            <span v-if="item.quantity != null">
              {{ item.quantity }}
            </span>
          </td>
          <td class="">
            <span v-if="item.totalPriceTTC != null">
              {{ item.totalPriceTTC | toFixedWithSpace }}
            </span>
          </td>
          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }} / {{ item.stock.reference }}
            </span>
          </td>
          <td>
            <span v-if="item.user">
              {{ item.user.name }}
            </span>
          </td>
          <td class="d-flex">
            <button
              type="button"
              @click="remove(item.id)"
              class="btn text-danger"
            >
              <i class="bi bi-trash-fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-0">
          <td></td>

          <td>Total :</td>
          <td class=""></td>

          <td>
            <span
              v-if="totalPriceHTItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalPriceHTItems | toFixedWithSpace }}
            </span>
          </td>

          <td>
            <span
              v-if="totalQuantityItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalQuantityItems }}
            </span>
          </td>

          <td>
            <span
              v-if="totalPriceTTCItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalPriceTTCItems | toFixedWithSpace }}
            </span>
          </td>

          <td>
            TVA :
            <span
              v-if="totalPriceTTCItems != null && totalPriceHTItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ (totalPriceTTCItems - totalPriceHTItems) | toFixedWithSpace }}
            </span>
          </td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à l'achat ( Quantité totale
            : <strong>{{ totalQuantityItems }}</strong> Kg, Mantant totale :
            <strong>
              {{ totalPriceTTCItems }}
            </strong>
            DH, Nombre d'articles :

            <strong>{{ numberItems }}</strong> ) et correctes.
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          type="button"
          class="btn btn-success"
          @click="validated()"
          :disabled="!accept ? true : false"
          v-if="accept"
        >
          <i class="bi bi-arrow-right-circle"></i> Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import CreateModalVue from "../../../components/modals/purchases/items/CreateModal.vue";
export default {
  components: {
    CreateModalVue,
  },
  data() {
    return {
      item: {},
      purchase_item: {
        priceTTC: 0,
        priceHT: 0,
        totalPriceHT: 0,
        totalPriceTTC: 0,
        quantity: 1,
      },
      section_reference: null,
      item_reference: null,
      accept: false,
      order_reference: "",
      showModal: false,
      selectedItem: {},
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),

    ...mapGetters("purchase", {
      purchase_items: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      totalPriceHTItems: "totalPriceHTItems",
      numberItems: "numberItems",
      errors: "getErrors",
    }),
    ...mapGetters("order", {
      orders: "getOrderSuppliers",
    }),

    ...mapGetters("stock", {
      stocks: "getStocks",
      stockFullNames: "getStockFullNames",
    }),

    filteredStocks() {
      return this.stocks.filter(
        (stock) => stock.item_reference === this.selectedItem.reference
      );
    },
    filteredItems() {
      if (this.section_reference == null) {
        return this.items;
      }

      return this.items.filter(
        (item) => item.section_reference == this.section_reference
      );
    },
  },
  methods: {
    async save(data) {
      await (data.item_reference = this.selectedItem.reference);
      try {
        await this.$store.dispatch("purchase/storeItem", data);
        await (this.purchase_item = {
          priceTTC: 0,
          priceHT: 0,
          totalPriceHT: 0,
          totalPriceTTC: 0,
          quantity: 1,
        });
        await (this.section_reference = null);
        await (this.item_reference = null);
        await (this.selectedItem = {});
        await this.$store.dispatch("stock/getAll");
      } catch (error) {}
    },

    async saveItem(data) {
      try {
        let formData = new FormData();

        formData.append("fullName", data.fullName);
        if (data.description) formData.append("description", data.description);
        if (data.tva) formData.append("tva", data.tva);
        if (data.code) formData.append("code", data.code);
        if (data.priceTTC) formData.append("priceTTC", data.priceTTC);
        if (data.measuring) formData.append("measuring", data.measuring);
        formData.append("section_reference", data.section_reference);

        await this.$store.dispatch("item/storeAndRouteToPurchase", formData);
        await this.$store.dispatch("item/getAll");

        await setInterval((this.item = {}), 1000);
        if (this.$route.query.item_reference != "") {
          await (this.item_reference = this.$route.query.item_reference);
          await this.onItemChangeSelected();
        }
        await (this.showModal = false);
      } catch (error) {}
    },
    async remove(_id) {
      let id = _id;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  ?",
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("purchase/deleteItem", id);
          }
        },
      });
    },
    async refresh() {
      await this.$store.dispatch("stock/fetchStockFullNames");
      await this.$store.dispatch("order/getSupplierOrders");
      await this.$store.dispatch("section/getAll");
      await this.$store.dispatch("item/getAll");
      await this.$store.dispatch("purchase/getAllItems");
      await this.$store.dispatch("stock/getAll");
    },
    async validated() {
      this.$router.push({ name: "purchases-create" });
    },
    async importData(data) {
      let reference = data;
      this.$router.push({
        name: "purchases-items-create",
        query: { supplier_reference: reference },
      });
      await this.$confirm({
        message: "Vous voulez importer les articles de commande : " + reference,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("purchase/uploadSupplierOrder", reference);
          }
        },
      });
    },

    async onItemChangeSelected() {
      await (this.purchase_item.priceHT = 0.0);
      await (this.purchase_item.priceTTC = 0.0);
      await (this.purchase_item.quantity = 1);
      await (this.selectedItem = this.items.find(
        (p) => p.reference == this.item_reference
      ));
      await console.log(this.item_reference);
      await console.log(this.items);
      // await console.log(this.selectedItem);
      if (this.selectedItem != null) {
        await console.log("***", this.selectedItem);
        await (this.item_reference = "");
      }
      // // await (this.item_reference = "");
    },

    async calculatePriceHT() {
      this.purchase_item.priceHT =
        Math.round(
          (this.purchase_item.priceTTC / (1 + this.selectedItem.tva / 100)) *
            100
        ) / 100;
    },
    async calculatePriceTTC() {
      this.purchase_item.priceTTC =
        Math.round(
          this.purchase_item.priceHT * (1 + this.selectedItem.tva / 100) * 100
        ) / 100;
    },

    async calculateTotalPrice() {
      await (this.purchase_item.totalPriceTTC = Math.round(
        this.purchase_item.priceTTC * this.purchase_item.quantity
      ));

      await (this.purchase_item.totalPriceHT = Math.round(
        this.purchase_item.priceHT * this.purchase_item.quantity
      ));
    },
  },
  async beforeMount() {
    this.$store.dispatch("stock/fetchStockFullNames");

    await this.$store.dispatch("order/getSupplierOrders");
    await this.$store.dispatch("section/getAll");
    await this.$store.dispatch("item/getAll");
    await this.$store.dispatch("purchase/getAllItems");
    await this.$store.dispatch("stock/getAll");

    if (this.$route.query.item_reference != "") {
      await (this.item_reference = this.$route.query.item_reference);
      await this.onItemChangeSelected();
    }
  },

  filters: {
    date: function (date) {
      return moment(date).format("DD-MM-yyyy");
    },

    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },

  watch: {
    // async selectedItem() {
    //   return await this.filteredItems.find(
    //     (item) => item.reference === this.purchase_item.item_reference
    //   );
    // },
  },
};
</script>
<style scoped>
#modal {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  position: absolute;
  z-index: 1245603;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* opacity: .2; */
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
#modal > div {
  /* text-align: center; */
  background-color: #fff;
  left: auto;
  right: auto;
  width: 750px;
  height: 550px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  padding: 1rem !important;
}
</style>
